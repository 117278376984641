import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

// import AppHeader from "../layout/AppHeader.vue";
// import AppFooter from "../layout/AppFooter.vue";
import Sidebar from "./layout/Sidebar.vue";
import TopBar from "./layout/TopBar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/app",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    components: {
      default: () => import("./views/auth/Login.vue"),
    },
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/create-account",
    name: "CreateAccount",
    components: {
      default: () => import("./views/auth/CreateAccount.vue"),
    },
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    components: {
      default: () => import("./views/auth/ForgotPassword.vue"),
    },
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/app",
    name: "App",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/Music.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/split",
    name: "Split",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/Split.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/milestones",
    name: "Milestones",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/Milestones.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/release/:id",
    name: "Release",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/Release.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/activities",
    name: "Activities",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/Activities.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "Account",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/Account.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/wallet",
    name: "Wallet",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/Wallet.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-profile",
    name: "edit-profile",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/EditProfile.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/update-password",
    name: "update-password",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/UpdatePassword.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/update-bank-account",
    name: "update-bank-account",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/UpdateBankAccount.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/update-social-account",
    name: "update-social-account",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/UpdateSocialAccount.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/apps",
    name: "apps",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/Apps.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/upgrade-plan",
    name: "upgrade-plan",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/UpgradePlan.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subscription-complete",
    name: "subscription-complete",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/SubscriptionComplete.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/new-release",
    name: "new-release",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/NewRelease.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-release/:id",
    name: "edit-release",
    components: {
      sidebar: Sidebar,
      topNav: TopBar,
      default: () => import("./views/app/EditRelease.vue"),
    },
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn) {
      next("/login");
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.isLoggedIn) {
      next({
        path: "/app",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
