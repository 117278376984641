import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; //Vuesax styles
import "material-icons/iconfont/material-icons.css";
import VueIziToast from "vue-izitoast";
import VueCryptojs from "vue-cryptojs";
import "izitoast/dist/css/iziToast.min.css";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import Vue2Filters from "vue2-filters";
import moment from "moment";
import ECharts from "vue-echarts";
import { use } from "echarts/core";
// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent, TooltipComponent, LegendComponent, TitleComponent } from "echarts/components";
import { PieChart, LineChart, BarChart } from "echarts/charts";
import VueConfetti from 'vue-confetti'

Vue.config.productionTip = false;

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  PieChart,
  LegendComponent,
  LineChart,
  TitleComponent,
]);

Vue.component("v-chart", ECharts);
Vue.prototype.moment = moment;

Vue.use(Vue2Filters);
Vue.use(VueConfetti)
Vue.use(VuePlyr, {
  plyr: {},
});
Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: "#f88f80",
      success: "rgb(23, 201, 100)",
      danger: "rgb(242, 19, 93)",
      warning: "rgb(255, 130, 0)",
      dark: "rgb(36, 33, 69)",
    },
  },
});

Vue.use(VueIziToast);
Vue.use(VueCryptojs);
Vue.prototype.$passPhrase = store.getters.passPhrase;
Vue.prototype.$toastPosition = {
  position: "topRight",
};

Vue.mixin({
  methods: {
    $formatCurrency: (amount, currency) => {
      if (amount && currency) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currency,
        }).format(amount);
      }

      return amount;

    }
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
