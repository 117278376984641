export const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, token, user) {
    state.status = "success";
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.token = "";
  },
  audioPlay(state, audioPlay) {
    state.audioPlay = audioPlay;
  },
  SET_ADD_TRACKS(data) {
    state.add_tracks = data;
  },
  ADD_TRACK(state, track) {
    state.add_tracks.push(track);
  },
  REMOVE_TRACK(state, index) {
    state.add_tracks.splice(index, 1);
  },
  RESET_ADD_TRACKS(state) {
    state.add_tracks = [];
  }
};
