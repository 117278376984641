<template>
  <div class="">
    <nav
      class="navbar navbar-top navbar-expand bg-white border-bottom-bb d-none d-lg-block py-0 fixed w-full z-10 top-0 left-0"
      aria-label="navbar">
      <div class="container-fluid">
        <div class="collapse navbar-collapse pl-64" id="navbarSupportedContent">
          <p class="text-dark font-weight-bold mb-0 mr-sm-3">
            Welcome, {{ user.artist_name }} 👋
          </p>
          <!-- Navbar links -->
          <ul class="navbar-nav align-items-center ml-md-auto">
            <li class="nav-item d-xl-none">
              <!-- Sidenav toggler -->

              <div class="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin"
                data-target="#sidenav-main">
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </div>
            </li>
          </ul>
          <ul class="navbar-nav align-items-center ml-auto ml-md-0">
            <router-link to="/new-release"
              class="bg-primary text-white py-3 px-4 mr-3 text-sm hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
              <span class="flex items-center space-x-2">
                <PlusCircleIcon size="20" />
                <span>New Release</span>
              </span>
            </router-link>

            <li class="nav-item">
              <vs-dropdown v-if="user" class="outline-none">
                <a class="nav-link pr-0 pl-2" href="#">
                  <div class="media align-items-center">
                    <vs-avatar size="40px" :src="user.profile_photo_url || ''" :text="user.name" />
                    <div class="userLogDetails pl-1">
                      <p class="name">{{ user.name }}</p>
                      <p class="email">{{ user.email }}</p>
                    </div>
                  </div>
                </a>
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="$router.push('/account')" class="px-4 py-2 text-right">
                    Profile
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="logout" class="px-4 py-2 text-right">
                    Logout
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <nav class="navbar navbar-top bg-white border-bottom-bb d-block d-lg-none py-2 fixed w-full z-10 top-0 left-0"
      aria-label="navbar">
      <div class="">
        <div class="row align-items-center w-full mr-0">
          <div class="col-6">
            <p class="text-dark font-weight-bold mb-0 mr-sm-3 small pl-4">
              Welcome, {{ user.artist_name }} 👋
            </p>
          </div>
          <div class="col-6 pr-0">
            <div class="text-right d-flex justify-content-end">
              <vs-dropdown v-if="user" class="outline-none">
                <a class="nav-link pr-0 pl-2" href="#">
                  <div class="media align-items-center">
                    <span class="avatar avatar-sm rounded" style="background: transparent">
                      <img style="border-radius: 18%" :src="user.profile_photo_url || ''" />
                    </span>
                    <div class="userLogDetails">
                      <p class="name">{{ user.name }}</p>
                      <p class="email">{{ user.email }}</p>
                    </div>
                  </div>
                </a>
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="$router.push('/account')" class="px-4 py-2 text-right">
                    Profile
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="logout" class="px-4 py-2 text-right">
                    Logout
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>

              <button @click="sidebarActive = !sidebarActive" class="btn btn-link text-dark p-2">
                <vs-icon icon="menu"></vs-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <vs-sidebar parent="body" default-index="1" color="primary" class="app-sidebar block md:hidden" spacer
      v-model="sidebarActive">
      <div>
        <nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs bg-white sitedashboard-navbar mb-sidebar"
          id="sidenav-main">
          <div class="scrollbar-inner">
            <!-- Brand -->
            <div class="sidenav-header align-items-center">
              <router-link class="navbar-brand pl-0" to="/">
                <img src="/melior_lt.svg" class="navbar-brand-img" alt="logo" />
              </router-link>
            </div>
            <div class="navbar-inner px-0">
              <!-- Collapse -->
              <div class="px-4" id="sidenav-collapse-main">

                <!-- Nav items -->
                <ul class="navbar-nav mb-4" v-for="(item, index) in menus" :key="index">
                  <p class="subtitle">{{ item.title }}</p>
                  <li class="nav-item pr-3" v-for="(menu, index) in item.menu" :key="index">


                    <router-link v-if="menu.route" :to="menu.route" class="nav-link cursor-pointer flex justify-between">
                      <span class="flex items-center">
                        <component :is="menu.icon" size="17"></component>
                        <span class="nav-link-text pl-2">{{ menu.name }}</span>
                      </span>

                      <span v-if="menu.subMenu">
                        <ChevronDownIcon v-if="!menu.menuDisplay" size="17" class="float-right"></ChevronDownIcon>
                        <ChevronUpIcon v-else size="17" class="float-right"></ChevronUpIcon>
                      </span>
                    </router-link>

                    <span v-else @click="redirectUser(menu)" class="nav-link cursor-pointer flex justify-between">
                      <span class="flex items-center">
                        <component :is="menu.icon" size="17"></component>
                        <span class="nav-link-text pl-2">{{ menu.name }}</span>
                      </span>

                      <span v-if="menu.subMenu">
                        <ChevronDownIcon v-if="!menu.menuDisplay" size="17" class="float-right"></ChevronDownIcon>
                        <ChevronUpIcon v-else size="17" class="float-right"></ChevronUpIcon>
                      </span>
                    </span>

                    <ul v-if="menu.menuDisplay">
                      <li v-for="(subMenu, index) in menu.subMenu" :key="index" class="py-1 ">
                        <router-link :to="subMenu.route" class="text-xs ml-5 text-gray-400">
                          <span class="pl-2">{{ subMenu.name }}</span>
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul class="navbar-nav mb-4">
                  <li class="nav-item mt-5">
                    <button class="nav-link btn btn-link" @click="logout()">
                      <UsersIcon size="17"></UsersIcon>
                      <span class="nav-link-text  pl-2">Logout</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>

        <!-- <vs-sidebar-item class="text-center" :active-class="'active'">
          <div class="d-flex align-items-center">
            <div class="mr-3">
              <img
                class="avatar avatar-sm rounded-circle"
                :src="getDisplay()"
                alt="..."
              />
            </div>
            <div class="text-left">
              <h6 class="mb-0">{{ user.first_name }}</h6>
              <small class="text-muted">{{ user.email }}</small>
            </div>
          </div>
        </vs-sidebar-item> -->
        <div></div>
      </div>
    </vs-sidebar>
  </div>
</template>
<script>
import Sidebar from "./Sidebar.vue";

import {
  HomeIcon,
  Edit3Icon,
  KeyIcon,
  BriefcaseIcon,
  GlobeIcon,
  DatabaseIcon,
  UsersIcon,
  MusicIcon,
  ActivityIcon,
  DollarSignIcon,
  SettingsIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PercentIcon,
  UserIcon,
  PlusCircleIcon
} from "vue-feather-icons";
export default {
  name: "TopBar",
  components: {
    HomeIcon,
    Edit3Icon,
    KeyIcon,
    BriefcaseIcon,
    GlobeIcon,
    DatabaseIcon,
    UsersIcon,
    MusicIcon,
    ActivityIcon,
    DollarSignIcon,
    SettingsIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    UserIcon,
    PlusCircleIcon
  },
  name: "TopBar",
  data() {
    return {
      sidebarActive: false,
      menus: [
        {
          title: "Menu",
          menu: [
            {
              name: "Music",
              icon: MusicIcon,
              route: "/app",
            },
            {
              name: "Split",
              icon: PercentIcon,
              route: "/split",
            },
            {
              name: "Wallet",
              icon: DollarSignIcon,
              route: "/wallet",
            },
            {
              name: "Account",
              icon: UserIcon,
              route: "/account",
            },
            // {
            //   name: "Activities",
            //   icon: ActivityIcon,
            //   route: "/activities",
            // },
            // {
            //   name: "Account",
            //   icon: HomeIcon,
            //   route: "/account",
            // },
            {
              name: "Settings",
              icon: SettingsIcon,
              menuDisplay: false,
              subMenu: [
                {
                  name: "Update profile",
                  icon: "edit-profile",
                  route: "/edit-profile",
                },
                {
                  name: "Update password",
                  icon: "update-password",
                  route: "/update-password",
                },
                {
                  name: "Update bank account",
                  icon: "update-bank-account",
                  route: "/update-bank-account",
                },
                {
                  name: "Update social account",
                  icon: "update-social-account",
                  route: "/update-social-account",
                },
              ]
            },
          ],
        },
        // {
        //   title: "Others",
        //   menu: [
        //     // {
        //     //   name: "Apps",
        //     //   icon: DatabaseIcon,
        //     //   route: "/apps",
        //     // },
        //     {
        //       name: "Visit Website",
        //       icon: GlobeIcon,
        //       to: "https://melior.africa/",
        //     },
        //     {
        //       name: "Join our community",
        //       icon: UsersIcon,
        //       to: "https://t.me/+84_DqjEHtnRkMjE0",
        //     },
        //   ],
        // },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
  },
  methods: {
    getDisplay() {
      return "https://i.pravatar.cc/300";
    },
    redirectUser(menu) {
      if (menu.subMenu) {
        menu.menuDisplay = !menu.menuDisplay;
        return;
      }
      if (menu.to) {
        window.open(menu.to, "_blank");
      } else {
        this.$router.push(menu.route);
      }
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        location.href = "/";
      });
    },
  },
  created() { },
};
</script>
<style lang="scss" scoped>
.userLogDetails {
  padding: 0px 10px;
  text-align: left;
  color: black;

  .name {
    margin-bottom: 0px;
    font-size: 13px;
  }

  .email {
    margin-bottom: 0px;
    font-size: 10px;
  }
}

@media (max-width: 1199.98px) {
  .sidenav.mb-sidebar {
    transform: translateX(0px) !important;
  }
}
</style>
