<template>
  <nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs sitedashboard-navbar" id="sidenav-main">
    <div class="scrollbar-inner">
      <!-- Brand -->
      <div class="sidenav-header align-items-center">
        <router-link class="navbar-brand pl-0" to="/">
          <img src="/melior_lt.svg" class="navbar-brand-img" alt="logo" />
        </router-link>
      </div>
      <div class="navbar-inner">
        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">


          <!-- Nav items -->
          <ul class="navbar-nav mb-4" v-for="(item, index) in menus" :key="index">
            <p class="subtitle">{{ item.title }}</p>
            <li class="nav-item pr-3" v-for="(menu, index) in item.menu" :key="index">


              <router-link v-if="menu.route" :to="menu.route" class="nav-link cursor-pointer flex justify-between">
                <span class="flex items-center">
                  <component :is="menu.icon" size="17"></component>
                  <span class="nav-link-text pl-2">{{ menu.name }}</span>
                </span>

                <span v-if="menu.subMenu">
                  <ChevronDownIcon v-if="!menu.menuDisplay" size="17" class="float-right"></ChevronDownIcon>
                  <ChevronUpIcon v-else size="17" class="float-right"></ChevronUpIcon>
                </span>
              </router-link>

              <span v-else @click="redirectUser(menu)" class="nav-link cursor-pointer flex justify-between">
                <span class="flex items-center">
                  <component :is="menu.icon" size="17"></component>
                  <span class="nav-link-text pl-2">{{ menu.name }}</span>
                </span>

                <span v-if="menu.subMenu">
                  <ChevronDownIcon v-if="!menu.menuDisplay" size="17" class="float-right"></ChevronDownIcon>
                  <ChevronUpIcon v-else size="17" class="float-right"></ChevronUpIcon>
                </span>
              </span>

              <ul v-if="menu.menuDisplay">
                <li v-for="(subMenu, index) in menu.subMenu" :key="index" class="py-1 ">
                  <router-link :to="subMenu.route" class="text-xs ml-5 text-gray-400">
                    <span class="pl-2">{{ subMenu.name }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  HomeIcon,
  Edit3Icon,
  KeyIcon,
  BriefcaseIcon,
  GlobeIcon,
  DatabaseIcon,
  UsersIcon,
  MusicIcon,
  ActivityIcon,
  DollarSignIcon,
  SettingsIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PercentIcon,
  UserIcon,
  StarIcon
} from "vue-feather-icons";
export default {
  components: {
    HomeIcon,
    Edit3Icon,
    KeyIcon,
    BriefcaseIcon,
    GlobeIcon,
    DatabaseIcon,
    UsersIcon,
    MusicIcon,
    ActivityIcon,
    DollarSignIcon,
    SettingsIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    StarIcon
  },
  data() {
    return {
      menus: [
        {
          title: "Menu",
          menu: [
            {
              name: "Music",
              icon: MusicIcon,
              route: "/app",
            },
            {
              name: "Split",
              icon: PercentIcon,
              route: "/split",
            },
            // {
            //  name: "Milestones",
            //  icon: StarIcon,
            //   route: "/milestones",
            // },
            {
              name: "Wallet",
              icon: DollarSignIcon,
              route: "/wallet",
            },
            {
              name: "Account",
              icon: UserIcon,
              route: "/account",
            },
            // {
            //   name: "Activities",
            //   icon: ActivityIcon,
            //   route: "/activities",
            // },
            // {
            //   name: "Account",
            //   icon: HomeIcon,
            //   route: "/account",
            // },
            {
              name: "Settings",
              icon: SettingsIcon,
              menuDisplay: false,
              subMenu: [
                {
                  name: "Update profile",
                  icon: "edit-profile",
                  route: "/edit-profile",
                },
                {
                  name: "Update password",
                  icon: "update-password",
                  route: "/update-password",
                },
                {
                  name: "Update bank account",
                  icon: "update-bank-account",
                  route: "/update-bank-account",
                },
                {
                  name: "Update social account",
                  icon: "update-social-account",
                  route: "/update-social-account",
                },
              ]
            },
          ],
        },
        // {
        //   title: "Others",
        //   menu: [
        //     // {
        //     //   name: "Apps",
        //     //   icon: DatabaseIcon,
        //     //   route: "/apps",
        //     // },
        //     {
        //       name: "Visit Website",
        //       icon: GlobeIcon,
        //       to: "https://melior.africa/",
        //     },
        //     {
        //       name: "Join our community",
        //       icon: UsersIcon,
        //       to: "https://t.me/+84_DqjEHtnRkMjE0",
        //     },
        //   ],
        // },
      ],
    };
  },
  methods: {
    redirectUser(menu) {
      if (menu.subMenu) {
        menu.menuDisplay = !menu.menuDisplay;
        return;
      }
      if (menu.to) {
        window.open(menu.to, "_blank");
      } else {
        this.$router.push(menu.route);
      }
    },
  },
};
</script>
