export const state = {
  customer: null,
  token: localStorage.getItem("token") || "",
  user: localStorage.getItem("user") || "",
  passPhrase: "!meliorApp@.132]13",
  categorySelect: "",
  paymentSent: false,
  audioPlay: {
    status: false,
    source: "",
  },
  notificationSystem: {
    options: {
      ballon: {
        balloon: true,
        position: "bottomCenter",
      },
      info: {
        position: "bottomCenter",
      },
      success: {
        position: "bottomCenter",
      },
      warning: {
        position: "bottomCenter",
      },
      error: {
        position: "bottomCenter",
      },
    },
  },
  add_tracks: [],
};

export default state;
