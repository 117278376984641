// axios
import axios from "axios";
// https://api.melior.africa
// https://staging.melior.africa/
const baseURL = "https://api.melior.africa/v2";

export default axios.create({
  baseURL,
  // You can add your headers here
});
